import React from 'react';
import { graphql } from 'gatsby'

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import perspective from '../assets/images/perspective.jpg';
import mountain from '../assets/images/mountain.jpg';
import highfive from '../assets/images/highfive.jpg';
import config from '../../config';

const IndexPage = ({data}) => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            IT Systems are our windows to the world
          </h2>
          <p>
            Let us help you create secure, reliable, scalable, performant, and feature-rich systems that will empower your team and impress your customers.
          </p>
        </header>
        <ul className="icons major row">
          <li className="col-4">
            <span className="icon top fa-gem major style1"/>
            <p className="vertical">
              Give your customers the clearest and brightest view of your services.
            </p>
          </li>
          <li className="col-4">
            <span className="icon top solid fa-code major style3"/>
            <p className="vertical">
              Gain valuable insights into business performance, bottlenecks, and customer experience.
            </p>
          </li>
          <li className="col-4">
            <span className="icon top fa-heart major style2"/>
            <p className="vertical">
              Inspire customer loyalty and repeat business.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={perspective} alt="" />
        </div>
        <div className="content">
          <h2>
            It's all about perspective
          </h2>
          <p>
            We understand the importance creating a long-term strategic roadmap
            for your IT systems even though the most pressing short-term need
            may be to put out a few fires first. We work to ensure every engagement
            moves you forward in the right direction.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={mountain} alt="" />
        </div>
        <div className="content">
          <h2>
            Your aspirations and challenges are unique
          </h2>
          <p>
            We have over 25 years of experience working with businesses of all sizes
            from startups to Fortune 50 corporations. We seek to understand your specific
            goals and dreams and collaborate with you on a custom plan to conquer your
            mountain.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={highfive} alt="" />
        </div>
        <div className="content">
          <h2>
            We're not happy until you and your customers are happy
          </h2>
          <p>
            At the end of the day, everyone wants to go home feeling good about
            their accomplishments and that they made a difference in someone's
            life.  We know you feel that way about your business, and your success
            is our success.  We don't go home until the job is done.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>What is a 'Fidelius', and why would I want to consult with one?</h2>
          <p>
            You may be familiar with the U.S. Marine Corps Motto, <i>"Semper Fidelis"</i>,
            which means "Always Faithful". <i>"Fidelius"</i> is a comparative from the
            same Latin root word and means "More Trustworthy".
          </p>
          <p>
            Our goal at Fidelius Consulting is to become your trusted IT advocate.
            We will work hard to earn and keep your business and to ensure that our
            deliverables exceed your expectations.
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-cog">
            <h3>Technology Options</h3>
            <p>
              We aren't locked into a single product, platform, or language.
              We won't try to sell you a solution just because it is the only
              one in our back pocket.  We work with your team to craft a
              solution that aligns with your unique situation.
            </p>
          </li>
          <li className="icon solid fa-handshake">
            <h3>Trusted Partners</h3>
            <p>
              If your solution requires additional services, we are connected with
              skilled and trusted partners who have our same dedication to quality
              and customer satisfaction. We will engage our partners as necessary to
              ensure your needs are met.
            </p>
          </li>
          <li className="icon solid fa-code">
            <h3>Custom Development</h3>
            <p>
              We provide custom software development services in both Java and C# .NET
              including integration with popular document management and workflow systems.
            </p>
          </li>
          <li className="icon solid fa-project-diagram">
            <h3>Process Management</h3>
            <p>
              We can assist you with process documentation and re-engineering. We can
              automate your processes using BPM tools such as Camunda or RPA tools
              like UiPath.
            </p>
          </li>
          <li className="icon solid fa-balance-scale">
            <h3>Open Standards</h3>
            <p>
              One way to avoid vendor lock-in and supply chain risk is to leverage
              open standards. For business process management efforts we recommend using
              tools that support the Object Management Group's BPMN, CMMN, and DMN
              standards.
            </p>
          </li>
          <li className="icon solid fa-stopwatch">
            <h3>Business Agility</h3>
            <p>
              The ability to adapt to rapidly changing customer demands and
              business environment is critical. We can help you improve your
              time-to-market for new solutions and enhancements to your existing
              systems.
            </p>
          </li>
          <li className="icon solid fa-chalkboard-teacher">
            <h3>Training and Support</h3>
            <p>
              We provide training and mentoring for organizations that want 
              to become self-supporting after the conclusion of an engagement.
              We offer longer-term support options on a subscription basis.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Show the Love</h3>
            <p>
              Slow and broken systems are a major source of frustration for both
              customers and employees. Let us help you show some love to your
              staff and your customers so everyone goes home with a smile!
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>How can we help you?</h2>
          <p>
            Contact us today to discuss how we can help you improve your customer
            satisfaction and employee engagement through enhanced IT solutions.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href={"mailto:" + data.site.siteMetadata.contactEmail + "?subject=Tell%20me%20more%20about%20your%20services" } className="button fit">
              Contact Us
            </a>
            <ul className="icons">
              {config.socialLinks.map(social => {
                const { style, icon, name, url } = social;
                return (
                  <li key={url}>
                    <a href={url} className={`icon ${style} ${icon}`}>
                      <span className="label">{name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        contactEmail
      }
    }
  }
`

export default IndexPage